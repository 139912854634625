import React from 'react';

import pic4 from '../images/pic04.jpg';
import fcc_responsive_web_design from '../assets/pdfs/fcc_responsive_web_design.pdf';
import fcc_javascript_algorithms_and_datastructures from '../assets/pdfs/fcc_javascript_algorithms_and_datastructures.pdf';
import zenva_html from '../assets/pdfs/zenva_html.pdf';
import zenva_css from '../assets/pdfs/zenva_css.pdf';
import zenva_responsive_web_design from '../assets/pdfs/zenva_responsive_web_design.pdf';
import zenva_beginners_javascript from '../assets/pdfs/zenva_beginners_javascript.pdf';
import zenva_applications_with_vue from '../assets/pdfs/zenva_applications_with_vue.pdf';
import resume from '../assets/pdfs/efranklin_Frontend Developer_2022.pdf';

import Layout from '../components/common/layout';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';

function Experience() {
  return (
    <div>
      <h1>EXPERIENCE</h1>
      <hr></hr>
      <h2>Umbraco Frontend Developer</h2>
      <h3>GRYT Health</h3>
      <h4><em>September 2021 - Present</em></h4>
      <ul>
        <li>Create and update conference sites built on Umbraco CMS.</li>
        <li>Utilize Razor Markup to make use of templates and partials in the composition of individual pages for each site.</li>
        <li>Use of standard CSS and Bootstrap to tailor each site’s styles to match that of the wireframe design provided by our design team.</li>
        <li>Implement JavaScript/jQuery solutions to execute tasks on the site based on the site’s requirements. </li>
        <li>Develop and maintain departmental documentation such as how-to guides, code standards, and naming conventions.</li>
      </ul>
      <h2>Frontend Web Developer</h2>
      <h3>A Thousand Words</h3>
      <h4><em>April 2021 - September 2021</em></h4>
      <ul>
        <li>Developed a React application using the Unsplash API.</li>
        <li>Implemented dynamic routing through React-Router.</li>
        <li>Utilized infinite scrolling to minimize renders while retrieving data.</li>
        <li>Implemented Redux and Redux Persist for better state management.</li>
        <li>Version control maintained using git.</li>
        <li>Utilized Styled-Components for better CSS control and organization.</li>
      </ul>
      <h2>Systems Analyst</h2>
      <h3>Williamsfield Valley Veneer, Inc.</h3>
      <h4><em>November 2017 - September 2021</em></h4>
      <ul>
        <li>Implementation and maintenance of WordPress website</li>
        <li>Server and workstation maintenance and setup</li>
        <li>Help desk support</li>
        <li>Access database maintenance</li>
        <li>Implementation and management of paperless environment</li>
        <li>Security equipment installation and support</li>
        <li>Technology-related aquisitions</li>
      </ul>
      <h2>Systems Analyst</h2>
      <h3>Bruce and Merrilees Electric Co.</h3>
      <h4><em>September 2010 - October 2017</em></h4>
      <ul>
        <li>Physical and virtual server/workstation maintenance</li>
        <li>Help desk support</li>
        <li>Communications account maintenance</li>
        <li>Active Directory maintenance</li>
        <li>Intranet maintenance</li>
        <li>Management of social media accounts</li>
      </ul>
      <h2>Customer Relations Specialist</h2>
      <h3>Office Team (as a temp for Bruce and Merrilees Electric Co.)</h3>
      <h4><em>May 2010 - September 2010</em></h4>
      <ul>
        <li>Receive and route all incoming calls</li>
        <li>Access database management</li>
        <li>Help desk support</li>
      </ul>
    </div>
  )
}

function Education() {
  return (
    <div>
      <h1>EDUCATION</h1>
      <hr></hr>
      <h2>America Business and Technology University</h2>
      <h3><em>Network Administration and Internet Security, A.A.S.</em></h3>
      <h4>2017</h4>
      <h2>ITT Technical Institute</h2>
      <h3><em>Network Systems Administration, A.A.S.</em></h3>
      <h4>2015 - 2016</h4>
    </div>
  )
}

function Skills() {
  return (
    <div>
      <h1>SKILLS</h1>
      <hr></hr>
      <h2>Web Development</h2>
      <ul>
        <li>React</li>
        <li>Redux</li>
        <li>React-Router</li>
        <li>Styled-Components</li>
        <li>Redux Thunk</li>
        <li>Redux Persist</li>
        <li>JavaScript (including ES6)</li>
        <li>HTML5</li>
        <li>CSS</li>
        <li>Responsive Web Design</li>
        <li>Git</li>
        <li>GitHub</li>
        <li>Umbraco CMS v8</li>
        <li>Razor Markup</li>
        <li>jQuery</li>
        <li>Bootstrap</li>
        <li>Sass</li>
      </ul>
      <h2>Advanced Computer Skills</h2>
      <ul>
        <li>Miscrosoft Office</li>
        <li>Advanced troubleshooting</li>
        <li>65wpm typing speed</li>
      </ul>
      <h2>Soft Skills</h2>
      <ul>
        <li>Works well with upper management and co-workers</li>
        <li>Co-worker support and training experience</li>
        <li>Flexible - willing to accept new challenges with an open mind</li>
      </ul>
    </div>
  )
}

function Certificates() {
  return (
    <div>
      <h1>Certificates</h1>
      <hr></hr>
      <h2><a href={fcc_responsive_web_design}>Responsive Web Design</a></h2>
      <h4><em>FreeCodeCamp</em></h4>
      <h2><a href={fcc_javascript_algorithms_and_datastructures}>JavaScript Algorithms and Data Structures</a></h2>
      <h4><em>FreeCodeCamp</em></h4>
      <h2><a href={zenva_html}>Bite-Sized HTML</a></h2>
      <h4><em>Zenva Academy</em></h4>
      <h2><a href={zenva_css}>Bite-Sized CSS</a></h2>
      <h4><em>Zenva Academy</em></h4>
      <h2><a href={zenva_responsive_web_design}>Responsive Web-Design for Beginners</a></h2>
      <h4><em>Zenva Academy</em></h4>
      <h2><a href={zenva_beginners_javascript}>The Complete Beginner's JavaScript Course</a></h2>
      <h4><em>Zenva Academy</em></h4>
      <h2><a href={zenva_applications_with_vue}>Build JavaScript Applications with Vue.js</a></h2>
      <h4><em>Zenva Academy</em></h4>
    </div>
  )
}

const SecondPage = () => (
  <Layout>
    <Header />
    <div id="resumeDownload">
      <a href={resume}><span className="fa fa-file-pdf-o" /></a>
    </div>
    <div id="wrapper">
      <section id="main" className="wrapper">
        <div className="inner" id="resumeInner">
          <h1 className="major">Resume</h1>
          <span className="image fit">
            <img src={pic4} alt="" />
          </span>
          <Experience />
          <Education />
          <Skills />
          <Certificates />
        </div>
      </section>
    </div>

    <Footer />
  </Layout>
);

export default SecondPage;
